.escuelitaMisCursos{
    height: 100%;
}

.escuelitaMisCursos > .sliderContainer{
    overflow: hidden;
}

.escuelitaMisCursos > .sliderContainer > .sliderItem{
    transition: 0s;
}
.perfilNiveles{
    padding: 0 82px 50px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
}

@media (max-width: 1300px){
    .perfilNiveles {
        padding: 0 20px 50px;
    }
}

@media (max-width: 1100px){
    .perfilNiveles {
        padding-top: 40px;
        grid-template-columns: repeat(2, 1fr);
    }

    .perfilViewContainer .secondaryButton.disabled {
        display: none;
    }
}

@media (max-width: 600px){
    .perfilNiveles {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
.validationSuccess{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.validationSuccess .validationItem{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.validationSuccess img{
    width: 160px;
}

.validationForm .validationSuccess p.validationSuccessTitle{
    padding: 40px 0 10px;
    font-size: 40px;
    font-weight: 700;
    color: var(--blink-main);
}

.validationForm .validationSuccess p.validationSuccessInfo{
    padding: 10px 0 60px;
    font-size: 23px;
    font-weight: 700;
    color: var(--blink-gray);
}

@media (max-width: 800px){
    .validationSuccess .validationItem {
        width: 100%;
    }
}

@media (max-width: 700px){
    .validationSuccess img {
        width: 120px;
    }

    .validationForm .validationSuccess p.validationSuccessTitle {
        font-size: 25px;
    }

    .validationForm .validationSuccess p.validationSuccessInfo {
        padding: 10px 0 30px;
        font-size: 15px;
    }
}


.clientesFilter{
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 48px;
}

.clientesFilter .filterButtons{
    display: flex;
    align-items: center;
    gap: 10px;
}

.clientesFilter .filterItem{
    gap: 15px;
    display: flex;
    align-items: center;
}

.clientesFilter .filterItem p{
    font-size: 12px;
    font-weight: 700;
}

.clientesFilter .secondaryButton{
    padding: 16px 20px;
    width: max-content;
}

@media (max-width: 1400px){
    .clientesFilter .searchBar{
        width: 30%;
    }
}

@media (max-width: 1200px){
    .clientesFilter{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 25px;
        grid-auto-flow: dense;
    }

    .clientesFilter .searchBar{
        width: 100%;
        grid-column: 1/3;
        order: 1;
    }

    .clientesFilter .paginationFilter{
        order: 2;
    }

    .clientesFilter .filterButtons{
        order: 3;
        justify-content: flex-end;
    }
}

@media (max-width: 900px){
    .clientes .clientesFilter .paginationFilter{
        grid-row: 3/4;
        grid-column: 1/3;
        justify-content: center;
    }

    .clientes .clientesFilter .filterButtons{
        grid-column: 1/3;
        justify-content: space-around;
    }
}

@media (max-width: 700px){
    .clientesFilter {
        margin-bottom: 20px;
    }

    .clientesFilter .paginationFilter{
        grid-column: 1/3;
        order: 3;
        justify-content: center;
    }

    .clientesFilter .filterButtons{
        grid-column: 1/3;
        order: 2;
        justify-content: space-around;
    }
}

@media (max-width: 600px){
    .clientesFilter .filterButtons:has(.secondaryButton){
        display: grid;
        gap: 25px;
        padding: 0 50px;
        grid-template-columns: repeat(2, 1fr);
    }

    .clientes .clientesFilter .filterButtons{
        display: flex;
        flex-direction: column;
        gap: 25px;
        align-items: center;
    }

    .clientesFilter .filterButtons .filterItem:has(.secondaryButton){
        grid-column: 1 / 3;
        display: flex;
        justify-content: center;
    }
}
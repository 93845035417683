.loginViewContainer{
    overflow: hidden;
    position: relative;
    /* min-height: 1024px; */
    /* height: 100%; */
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row nowrap;
    transition: .3s;
    background-color: var(--blink-main);
    min-height: calc(100vh - 100px);
}

.loginViewContainer:has(.largeSection){
    min-height: 1024px;
}


@media (max-width: 1200px) {
    .loginViewContainer:has(.loginForm){
        margin-top: 100px;
        align-items: flex-start;
    }
}

@media (max-width: 700px) {
    .loginViewContainer{
        margin-top: 0;
        align-items: center;
    }
}

@media (max-width: 500px) {
    .login > div{
        min-height: 100vh;
    }

    .loginViewContainer{
        margin-top: 62px;
        align-items: center;
        min-height: auto;
        padding: 30px 10px 0;
        /* height: calc(100vh - 62px); */
    }

    .loginViewContainer:has(.loginForm) {
        margin-top: 62px;
    }
}
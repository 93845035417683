.dashboardLiquidations{
    grid-column: 4/6;
    grid-row: 3/4;
}

.liquidationsContainer{
    flex-grow: 1;
    display: flex;
    gap: 24px;
}

.liquidationsContainer .row{
    display: flex;
    justify-content: space-between;
}

.liquidationsContainer .cell{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
}

.liquidationsContainer b{
 font-size: 19px;   
}

.liquidationsContainer .gridCard{
    color: var(--blink-main);
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
}

@media (max-width: 1200px){
    .liquidationsContainer .row {
        justify-content: space-around;
    }
}

@media (max-width: 500px){
    .liquidationsContainer {
        flex-direction: column;
    }
}
.escuelitaApuntes{
    padding: 24px 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.escuelitaApuntes .apunteItem{
    display: flex;
    justify-content: space-between;
    padding: 30px 20px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    cursor: pointer;
}

.escuelitaApuntes p{
    font-size: 15px;
    font-weight: 700;
}
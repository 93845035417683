.validationForm .validationItem{
    padding: 48px;
    background-color: #fff;
    box-shadow: 0px 0px 25px 15px rgb(0 0 0 / 25%);
    border-radius: 24px;
    max-width: 850px;
    margin: auto;
}

.validationForm .sliderItem{
    padding: 48px 80px;
}

.validationForm .validationItem p{
    font-size: 19px;
    font-weight: 500;
}

.validationForm .validationButton{
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 800px){
    .validationForm .sliderItem {
        padding: 30px 20px;
    }
}

@media (max-width: 500px){
    .validationForm .validationItem p {
        font-size: 14px;
        text-align: center;
    }

    .validationForm .validationItem {
        padding: 40px 20px;
    }

    .validationForm .validationButton {
        margin-top: 20px;
    }

    .validationForm .validationButton {
        justify-content: center;
    }
}
.ventasRelativeInfo .saleForm{
    padding: 60px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.ventasRelativeInfo .rowForm{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
}

.ventasRelativeInfo .formItem > label{
    font-size: 15px;
    font-weight: 500 !important;
    color: var(--blink-main);
}

.ventasRelativeInfo .saleForm.disabled .formItem > label{
    color: var(--blink-gray);
}

.ventasRelativeInfo .formItem{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.ventasRelativeInfo .inputContainer{
    display: flex;
    gap: 8px;
}

@media (max-width: 1200px){
    .ventasRelativeInfo .saleForm {
        padding: 20px;
    }
}

@media (max-width: 700px){
    .ventasRelativeInfo .rowForm {
        display: flex;
        flex-direction: column;
    }
}
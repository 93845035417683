.pdfView{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pdfView a{
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.pdfView .controls{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.pdfView .other{
    display: flex;
    align-items: center;
    gap: 8px;
    min-width: 150px;
}

.pdfView .pagination{
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 15px;
}

.pdfView button{
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 5px;
    border-radius: 5px;
    width: 35px;
    height: 35px;
}

.pdfView button:enabled{
    background-color: var(--blink-main);
    color: white;
    cursor: pointer;
}

.pdfView button:enabled svg{
    fill: white;
}

.pdfView button:disabled svg{
    fill: var(--blink-gray);
}


.pdfView button:disabled{
    background-color: #AEAEAE;
    color: var(--blink-gray);
}

.pdfView .pageInputContainer{
    display: flex;
    align-items: center;
    gap: 8px;
}

.react-pdf__Document{
    margin: 30px 0;
    height: 600px !important;
    width: 780px;
    flex-grow: 1;
    overflow: auto;
    background-color: #E6E6E6;
}

.react-pdf__Page__canvas{
    margin: auto;
    border-bottom: 1px solid #AEAEAE;
}

.react-pdf__Page__textContent, .react-pdf__Page__annotations{
    display: none;
}

@media (max-width: 600px){
    .react-pdf__Document {
        width: 490px;
    }

    .pdfView .controls {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 25px;
        width: 320px;
    }

    .pdfView .pagination {
        order: 2;
        grid-column: 1/3;
        display: flex;
        justify-content: space-between;
    }

    .pdfView .other{
        order: 1;
        grid-column: span 1;
        display: flex;
        min-width: auto;
    }
}

@media (max-width: 500px){
    .react-pdf__Document {
        width: 350px;
    }
}
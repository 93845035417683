.escuelitaViewContainer{
    min-height: 100%;
    padding: 25px 0;
    display: flex;
    flex-direction: column;
}

.escuelitaViewContainer .secondaryButton{
    position: fixed;
    bottom: 30px;
    right: 34px;
    z-index: 30;
}

@media (max-width: 1300px){
    .escuelitaViewContainer .escuelitaCurso .secondaryButton {
        position: relative;
        bottom: 0;
        right: 0;
        top: 0 !important;
    }
}
.ventasTitularInfo .saleForm{
    padding: 60px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.ventasTitularInfo .sliderHeaderInfoMain{
    display: flex;
    align-items: center;
    gap: 25px;
}

.ventasTitularInfo .rowForm{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
}

.ventasTitularInfo .formItem{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.ventasTitularInfo .formItem > label{
    font-size: 15px;
    font-weight: 500 !important;
    color: var(--blink-main);
}

.ventasTitularInfo .saleForm.disabled .formItem > label{
    color: var(--blink-gray);
}

.ventasTitularInfo .inputContainer{
    display: flex;
    gap: 8px;
}

.ventasTitularInfo .relativesHead{
    padding: 0 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ventasTitularInfo .relativesGrid{
    padding: 60px;
    display: grid;
    grid-template-columns: repeat(4, minmax(220px, 1fr));
    gap: 56px;
}

.ventasTitularInfo .relativeCard{
    position: relative;
    padding: 28px;
    border-radius: 20px;
    background-color: var(--blink-main);
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: .3s;
    text-align: center;
}

.ventasTitularInfo .relativeCard:hover{
    transform: scale(1.04);
}

.ventasTitularInfo .name{
    font-size: 19px;
    font-weight: 700;
}

.ventasTitularInfo .age{
    font-size: 13px;
    font-weight: 500;
}

.ventasTitularInfo .relation{
    font-size: 15px;
    font-weight: 700;
    margin-top: 8px;
}

@media (max-width: 1400px){
    .ventasTitularInfo .relativesGrid {
        gap: 20px;
    }
}

@media (max-width: 1200px){
    .ventasTitularInfo .saleForm {
        padding: 40px 20px;
    }

    .ventasTitularInfo .relativesHead {
        padding: 0 20px;
    }

    .ventasTitularInfo .relativesGrid {
        padding: 20px;
        grid-template-columns: repeat(3, minmax(170px, 1fr));
    }
}

@media (max-width: 750px){
    .ventasTitularInfo .relativesGrid {
        grid-template-columns: repeat(2, minmax(170px, 1fr));
    }
}

@media (max-width: 700px){
    .ventasTitularInfo .rowForm {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 500px){
    .ventasTitularInfo .relativesGrid {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .ventasTitularInfo .relativeCard {
        width: 250px;
    }
}
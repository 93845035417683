.validationViewContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 105px;
  background: linear-gradient(
    180.36deg,
    #a99bff -16.64%,
    rgba(124, 107, 237, 0.985381) 16.21%,
    rgba(71, 68, 204, 0.769681) 54.97%,
    #4744cc 99.66%
  );
}

.validationViewContainer .validationContainer {
  padding: 80px 0 0 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.validationViewContainer .validationTitle {
  font-size: 33px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 24px;
  padding: 0 80px;
}

@media (max-width: 1000px) {
  .validationViewContainer .validationTitle {
    font-size: 25px;
    text-align: center;
  }
}

@media (max-width: 800px) {
  .validationViewContainer .validationContainer {
    padding: 30px 0 0 0;
    justify-content: flex-start;
  }
}

@media (max-width: 500px) {
  .validationViewContainer {
    padding-top: 60px;
  }

  .validationViewContainer .validationTitle {
    text-align: center;
    font-size: 25px;
    padding: 0 20px;
  }
}

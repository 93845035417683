.section .banner {
  font-size: 93px;
  font-weight: 800;
  color: #fff;
  line-height: 110px;
  max-width: 650px;
}

.section .registerText {
  cursor: pointer;
  margin: 35px 0;
  text-align: center;
  color: var(--blink-main);
  font-size: 15px;
  font-weight: 700;
}

.section {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 120px;
  padding-top: 107px;
  justify-content: space-around;
  align-items: center;
  /* border: 1px dashed black; */
  box-sizing: border-box;
  flex: 0 0 auto;
  /* transition: .4s; */
}

.section .form {
  padding: 60px 70px 35px;
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 0.6);
}

.section .formItem {
  display: flex;
  flex-direction: column;
  margin: 12px 0;
  width: 330px;
}

.section .formSection p {
  color: #fff;
  font-size: 23px;
  font-weight: 700;
  margin-bottom: 30px;
}

.section label {
  margin-bottom: 8px;
  font-size: 15px;
  font-weight: 600;
  color: var(--blink-main);
}

.section .error label{
  color: red;
}

.section .formOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}

.section .formOptions p {
  font-size: 13px;
  font-weight: 500;
  color: var(--blink-main);
  margin: 0;
  cursor: pointer;
}

.section .secondaryButton {
  width: 100%;
}

.section .formItem p.errorLabel{
  color: red;
  font-size: 12px;
  font-weight: 500;
  margin: 5px 0 0 5px;
  visibility: hidden;
}

.section .formItem.error p.errorLabel{
  visibility: visible;
}

@media (max-width: 1400px) {
  .section {
    gap: 40px;
    padding: 40px;
  }

  .section .banner {
    font-size: 60px;
    line-height: 90px;
  }
}

@media (max-height: 750px) {
  .section {
    padding-top: 107px;
  }
}

@media (max-width: 1200px) {
  .section {
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 0 80px 30px;
  }

  .section.loginForm {
    padding: 20px;
  }

  .section .banner {
    text-align: center;
  }

  .section .formSection > p {
    text-align: center;
    font-size: 18px;
  }
}

@media (max-width: 700px) {
  .section {
    gap: 15px;
  }

  .section .banner {
    font-size: 40px;
    line-height: 60px;
  }

  .section .formSection p {
    text-align: center;
  }

  .registerForm .registerSlogan h2 {
    font-size: 20px;
  }

  .section.largeSection {
    padding: 0 20px 20px;
  }
}

@media (max-width: 500px) {
  .section {
    padding: 0;
    padding-top: 62px;
  }

  .section .form {
    padding: 20px 20px 10px 20px;
  }

  .section .formSection {
    height: 100%;
  }

  .section .secondaryButton {
    margin-bottom: 30px;
  }
}

@media (max-width: 500px) {
  .section .formItem {
    margin: 15px 0;
  }
}
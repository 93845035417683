.loader {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(0deg, #A99BFF 0%, rgba(124, 107, 237, 0.985381) 28.24%, #6966E9 61.57%, #4744CC 100%);
}

.loaderTitle {
    font-size: 70px;
    color: #fff;
    font-weight: bold;
    font-family: 'Comfortaa', cursive;
    animation: loaderAnimation 1800ms ease 0s infinite normal;
}

.progress-bar {
    margin: 15px 0;
    border-radius: 60px;
    overflow: hidden;
    width: 100%;
}

.progress-bar span {
    display: block;
}

.bar {
    background: rgba(0, 0, 0, 0.15);
}

.progress {
    animation: loader 1800ms;
    animation-iteration-count: 1;
    background: var(--blink-secondary);
    color: #fff;
    padding: 5px;
    width: 100%;
}

.progress-bar {
    max-width: 200px;
}

@keyframes loaderAnimation {
    0% {
        transform: scale(1);
    }

    15% {
        transform: scale(1.3);
    }

    30% {
        transform: scale(1);
    }

    45% {
        transform: rotate(0deg) scale(1.3);
    }

    60% {
        transform: rotate(360deg);
    }

    75% {
        transform: scale(1);
    }
}

@keyframes loader {
    0% {
        width: 0;
    }

    20% {
        width: 10%;
    }

    25% {
        width: 24%;
    }

    43% {
        width: 41%;
    }

    56% {
        width: 50%;
    }

    66% {
        width: 52%;
    }

    71% {
        width: 60%;
    }

    75% {
        width: 76%;
    }

    94% {
        width: 86%;
    }

    100% {
        width: 100%;
    }
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  overflow-x: hidden;
  color: var(--blink-gray);
}

body:not(:has(.panelContainer)) {
  background-color: var(--blink-main);
}

html:has(.panelContainer) {
  overflow-y: hidden;
}

li {
  font-family: "Poppins", sans-serif !important;
}

:root {
  --blink-main: #4744cc;
  --blink-secondary: #34e8ca;
  --blink-gray: #383838;
  --toastify-color-success: #4744cc;
}

#root:has(.panelContainer) {
  overflow: hidden;
  height: 100vh;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: var(--blink-main);
}

::-webkit-scrollbar {
  width: 7px;
  height: 10px;
  background-color: var(--blink-main);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--blink-secondary);
}

.escuelitaListaCursos{
    padding: 20px 140px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.escuelitaCard{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 26px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
}

.escuelitaCard.locked{
    background-color: #AEAEAE;
    color: var(--blink-gray);
}

.escuelitaCard.notApprobed{
    color: var(--blink-main);
    cursor: pointer;
}

.escuelitaCard.approbed{
    background-color: var(--blink-main);
    color: #fff;
    cursor: pointer;
}

.escuelitaCard .cardTitle{
    display: flex;
    align-items: center;
    gap: 30px;
}

.escuelitaCard .stars{
    display: flex;
    align-items: center;
}

.escuelitaCard .cardOptions{
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 15px;
    font-weight: 700;
}

.escuelitaCard .title{
    font-size: 19px;
    font-weight: 700;
}

@media (max-width: 1200px){
    .escuelitaListaCursos{
        padding: 20px;
    }
}

@media (max-width: 550px){
    .escuelitaMisExamenes .escuelitaCard {
        flex-direction: column;
        gap: 20px;
    }

    .escuelitaMisExamenes .escuelitaCard .cardTitle {
        width: 100%;
        justify-content: space-between;
    }

    .escuelitaMisExamenes .escuelitaCard .cardOptions {
        width: 100%;
        justify-content: flex-end;
    }
}
.customModal{
    background-color: rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 200;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.2s linear;
}

.customModal.visible{
    visibility: visible;
    opacity: 1;
}

.customModal .customMmodalContainer{
    background-color: #fff;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    position: relative;
    width: fit-content;
    max-width: 1150px;
    /* max-height: 90%; */
    /* min-width: 900px; */
    overflow: hidden;
}

.customModal .modalHeader{
    padding: 10px 24px;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    background-color: var(--blink-main);
}

.customModal .modalBody{
    padding: 56px 60px;
    height: 100%;
    overflow: hidden;
}

.withHeader .customModal .modalBody{
    padding: 24px;
}

.customModal .closeIcon{
    position: absolute;
    top: 0;
    right: 0;
    padding: 28px 28px 0 0;
    color: var(--blink-main);
}

.customModal .closeIcon.headerIcon{
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px 28px;
    color: #fff;
}

@media (max-width: 1200px){
    .customModal .customMmodalContainer:has(.clientesForm) {
        width: 100%;
        height: 100%;
        border-radius: 0;
    }

    .customModal .modalBody:has(.pdfView) {
        padding: 56px 20px;
    }

    .customModal .modalBody:has(.clientesForm) {
        overflow: auto;
        padding: 25px;
    }
}

@media (max-width: 600px){
    .customModal .customMmodalContainer {
        width: 100%;
        height: 100%;
        border-radius: 0;
    }

    .customModal .modalBody {
        overflow: auto;
    }
}
.ventasVentaViewContainer{
    padding: 25px 0;
    display: flex;
    flex-direction: column;
}

.ventasVentaViewContainer .sliderBarItem p {
    background-color: #AEAEAE;
}

.ventasVentaViewContainer .sliderBarItem:has(p:not(.selected):not(.completed)){
    background-color: #AEAEAE;
}

.ventasVentaViewContainer .sliderBarItem::before{
    content: "";
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: #AEAEAE;
}

.ventasVentaViewContainer .sliderBarItem:has(p.completed)::before{
    background-color: var(--blink-main);
}

.ventasVentaViewContainer .sliderBarItem .selected {
    background-color: #120F8D;
}

.ventasVentaViewContainer .sliderBarItem .completed {
    background-color: var(--blink-main);
}

.ventasVentaViewContainer .sliderBarItem p{
    cursor: default;
}

.ventasVentaViewContainer .sliderBarItem p:not(.selected):hover {
    background-color: var(--blink-main);
    z-index: initial;
    transition: 0s;
}

.ventasVentaViewContainer .sliderBarItem p:not(.selected):not(.completed):hover {
    background-color: #AEAEAE;
    z-index: initial;
    transition: 0s;
}

.ventasVentaViewContainer .secondaryButton{
    position: fixed;
    bottom: 30px;
    right: 34px;
}

.ventasVentaViewContainer .sliderControls{
    border: none;
    height: fit-content;
    background-color: #fff;
    color: var(--blink-main);
    cursor: pointer;
}

.ventasVentaViewContainer .sliderControls:disabled{
    color: #AEAEAE;
    cursor: default;
}
.escuelitaRespuestas{
    padding: 48px 0;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 25px 15px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    gap: 32px;
}

.escuelitaRespuestas .questionTitle{
    padding: 0 48px;
    font-size: 19px;
    font-weight: 500;
}

.escuelitaRespuestas .questionAnswers{
    padding: 24px 64px 16px;
}

.escuelitaRespuestas .correctAnswer{
    font-size: 15px;
    font-weight: 500;
    padding: 12px 42px;
}
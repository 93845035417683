.menu{
    min-width: 240px;
    width: 240px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    transition: .3s;
    background-color: #fff;
    /* z-index: 10; */
}

.menu.folded{
    min-width: 70px;
    width: 70px;
}

.menu.activeMenu{
    color: var(--blink-main);
}

.menu.inactiveMenu, .menu.semiActiveMenu{
    color: #AEAEAE;
}

.menu.inactiveMenu .menuItem.active{
    color: var(--blink-main);
    cursor: pointer;
}

.menu.semiActiveMenu .menuItem.activeMenuItem{
    background-color: var(--blink-main);
    color: #fff;
}

.menu.semiActiveMenu .menuItem.activeMenuItem:nth-child(1),
.menu.semiActiveMenu .menuItem.activeMenuItem:nth-child(6){
    background-color: var(--blink-main);
    color: #fff;
}

.menu.semiActiveMenu .menuItem:nth-child(1),
.menu.semiActiveMenu .menuItem:nth-child(6){
    color: var(--blink-main);
}

.menu.semiActiveMenu .menuItem:nth-child(1):not(.activeMenuItem):hover,
.menu.semiActiveMenu .menuItem:nth-child(6):not(.activeMenuItem):hover{
    background-color: rgba(71, 68, 204, 0.2);
}

.menu.semiActiveMenu .menuItem:nth-child(1),
.menu.semiActiveMenu .menuItem:nth-child(6){
    cursor: pointer;
    transition: .3s;
}

.menu.activeMenu .menuItem.activeMenuItem{
    background-color: var(--blink-main);
    color: #fff;
}

.menu.inactiveMenu .menuItem.activeMenuItem{
    background-color: #AEAEAE;
    color: #fff;
}

.menu.activeMenu .menuItem{
    cursor: pointer;
    transition: .3s;
}

.menu.inactiveMenu .menuItem{
    cursor: default;
}

.menu .menuItem{
    display: flex;
    padding: 20px 24px;
}

.menu.activeMenu .menuItem:not(.activeMenuItem):hover{
    background-color: rgba(71, 68, 204, 0.2);
}

.menu .menuItem p{
    font-size: 15px;
    font-weight: 700;
    padding-left: 24px;
    transition: .3s;
}

@media (max-width: 900px){
    .menu{
        display: none;
    }
}

@media (max-height: 700px){
    .menu .menuItem{
        padding: 14px 24px;
    }
}
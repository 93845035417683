.ventasPrepaga .quotationGrid {
    padding: 60px;
}

.ventasPrepaga .quotationGrid:not(:has(.MuiFormControl-root)) {
    display: grid;
    grid-template-columns: repeat(3, minmax(330px, 1fr));
    gap: 25px;
}

.ventasPrepaga .quotationCard{
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}

.ventasPrepaga .quotationHeader{
    display: flex;
    justify-content: space-between;
    font-size: 19px;
    font-weight: 500;
    color: var(--blink-main);
}

.ventasPrepaga ul{
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-weight: 500;
}

.ventasPrepaga ul:nth-child(1){
    color: var(--blink-main);
    font-size: 15px;
}

.ventasPrepaga ul:nth-child(2){
    font-size: 19px;
}

.ventasPrepaga li{
    height: 30px;
    display: flex;
    align-items: center;
}

.ventasPrepaga .quotationBody{
    display: flex;
    gap: 24px;
}

@media (max-width: 1200px){
    .ventasPrepaga .quotationGrid {
        padding: 20px;
    }
}

@media (max-width: 750px){
    .ventasPrepaga .quotationCard {
        width: 350px;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.flexTableCell {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.Toastify__toast {
  min-height: 48px !important;
}
.Toastify__toast .loaderTitle {
  font-size: 20px;
  margin-left: 30px;
}

.Toastify__toast-theme--colored:not(.Toastify__toast--success) {
  background-color: var(--blink-main) !important;
  color: #fff !important;
}

.Toastify__toast--success {
  background-color: var(--blink-main) !important;
  border-radius: 12px !important;
}

.Toastify__progress-bar {
  background-color: #6c69d6 !important;
}

.Toastify__toast-body {
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  text-align: center;
}

.Toastify__toast--success .Toastify__toast-icon,
.Toastify__toast--success .Toastify__close-button {
  display: none !important;
}

@media (max-width: 480px) {
  .Toastify__toast--success {
    border-radius: 0 !important;
  }
}

.dashboardGraph{
    grid-column: 1/3;
    grid-row: 1/3;
}

.dashboardGraph p {
    font-size: 15px;
    font-weight: 700;
}

.dashboardGraph svg{
    cursor: pointer;
}